$primary-boxShadow:
	rgba(0, 0, 0, 0.25) 0px 14px 28px,
	rgba(0, 0, 0, 0.22) 0px 10px 10px;

// radius
$primary-radius: 16px;
$sub-radius: 8px;
// font w
$primary-fontWeight: 400;
$bold-fontWeight: 700;
