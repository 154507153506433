.item {
	display: flex;
	align-items: center;
	border-radius: 8px;
	border: 1px solid light-dark(#787676, #363535);
	padding: 0px 12px;
	background-color: light-dark(#fff, #363535);
	margin-bottom: 4px;
	user-select: none;
}

.itemDragging {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05),
		rgba(0, 0, 0, 0.05) 0 10px 15px -5px, rgba(0, 0, 0, 0.04) 0 7px 7px -5px;
}

.symbol {
	font-size: 30px;
	font-weight: 700;
	width: 65px;
}
