@import '../../../global.module.scss';

.wrapperModalChangePass {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 400px;
	width: calc(100vw - 32px);
	outline: none;
	padding: 16px;

	.container {
		background: white;
		box-shadow: $primary-boxShadow;
		border-radius: $sub-radius;
		padding: 16px;
	}
}
