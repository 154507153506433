@import './About.scss';
@import './Header.scss';
@import './Footer.scss';
@import './Content.scss';

body {
	margin: 0;
	padding: 0;
	background-color: rgb(248, 246, 246);
}

section,
footer {
	padding: 4rem 0rem;
	padding-right: 60px;
	padding-left: 60px;

	@media only screen and (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;

		.mantine-Container-root {
			padding: 0px;
		}
	}
}
