.placeholder {
	user-select: none;
	position: absolute;
	top: 21px + 12px;
	left: 12px;
	color: #adb7c0;
	font-weight: 350;
	font-size: 14.5px;
	cursor: pointer;
}
