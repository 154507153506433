.wrapprer {
	div {
		box-sizing: border-box;
	}

	.body {
		width: 100%;
		padding-left: 250px;

		@media (max-width: 900px) {
			padding-left: 0;
		}
	}
}
