.containner,
.containnerFull {
	width: 25%;
	padding: 10px;
}

.containnerFull {
	width: 100%;
}

@media (min-width: 768px) and (max-width: 1300px) {
	.containner {
		width: 33%;
	}
}

@media (min-width: 460px) and (max-width: 768px) {
	.containner {
		width: 50%;
	}
}

@media (min-width: 0) and (max-width: 460px) {
	.containner {
		width: 100%;
	}
}
